<template>
  <b-card
    class="stockcreate-edit-wrapper"
  >
    <!-- form -->
    <b-form id="stockcreateForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="1调拨 2销售退货 3库位转移"
            label-for="type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="type"
              size="sm"
              v-model="stockcreate.type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="关联单据id 调拨：allot_outbound_inbound_item.inbound_id"
            label-for="rel_order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="rel_order_id"
              size="sm"
              v-model="stockcreate.rel_order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="关联单据明细id 调拨：allot_outbound_inbound_item.inbounditem_id"
            label-for="rel_item_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="rel_item_id"
              size="sm"
              v-model="stockcreate.rel_item_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库前stock_id"
            label-for="before_stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="before_stock_id"
              size="sm"
              v-model="stockcreate.before_stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库后stock_id"
            label-for="after_stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="after_stock_id"
              size="sm"
              v-model="stockcreate.after_stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 0良品 1不良品"
            label-for="stock_status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_status"
              size="sm"
              v-model="stockcreate.stock_status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库后stock_log表Id"
            label-for="log_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="log_id"
              size="sm"
              v-model="stockcreate.log_id"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import stockcreateStore from './stockcreateStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      stockcreate: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stockcreate')) store.registerModule('stockcreate', stockcreateStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockcreate')) store.unregisterModule('stockcreate')
    })

    const edit = function() {
      store.dispatch('stockcreate/edit', {id: this.id}).then(res => {
        this.stockcreate = res.data.data
      })
    }

    const view = function() {
      store.dispatch('stockcreate/view', {id: this.id}).then(res => {
        this.stockcreate = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('stockcreate/save', this.stockcreate).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
